import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import Navbar from './components/Navbar';
import Badge from './components/Badge';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import PageLoader from './components/PageLoader';
import Section from './components/Section';
import Tokenomics from './components/Tokenomics';
import JoinUs from './components/JoinUs';
import MemeGenerator from './components/MemeGenerator';
import Roadmap from './components/Roadmap';
import { Slider } from '@material-tailwind/react';
import Scrolling from './components/Scrolling';
import FourChan from './components/FourChan';

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const App: FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000); // Espera 3 segundos antes de establecer isLoading en false

        return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
    }, []);

    return (
        <Router>
            {isLoading ? (
                <PageLoader />
            ) : (
                <Routes>
                    <Route path="/" element={
                        <>
                            <Context>
                                <Content />
                            </Context>
                        </>
                    } />
                </Routes>
            )}
        </Router>
    );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new UnsafeBurnerWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {
    return (
        <>
            {/* <Navbar /> */}
            {/* <Badge /> */}
            {/* <Main /> */}
            <FourChan />
            {/* <Badge /> */}
            {/* <Tokenomics /> */}
            {/* <MemeGenerator /> */}
            {/* <Scrolling /> */}
            {/* <Section /> */}
            {/* <Roadmap /> */}
            {/* <JoinUs /> */}
            {/* <WalletMultiButton /> */}
        </>
    );
};
