import React from 'react';
import GifLoader from '../img/loading8.gif';

const PageLoader: React.FC = () => {
    return (
        <div className="">
            {/* <div style={{ backgroundImage: `url(${GifLoader})`, backgroundPosition: 'center', height: '100vh' }} /> */}
        </div>
    );
};

export default PageLoader;
