import React from 'react';
import chanLogo from '../img/chanLogo.svg';
import Logo from '../img/15.png';
import meme2 from '../img/15.png';
import meme3 from '../img/15.png';
import meme4 from '../img/15.png';

const FourChan: React.FC = () => {
    return (
        <div className="bg-white max-w-7xl mx-auto font-sans">
            <img src={chanLogo} alt="4chan logo" className="mx-auto my-20" />
            <h1 className='text-5xl text-center text-red-700 mt-20 font-bold'>
                / $rufus / - The next big thing
            </h1>
            <div className="flex flex-col items-center justify-center mt-5 mb-10 gap-5 text-2xl border-t pt-5 font-bold">
                <a href="#soon" className="text-blue-900 hover:underline" target="_blank" rel="noreferrer">
                    [ Telegram ]
                </a>
                <a href="https://dexscreener.com/" className="text-blue-900 hover:underline" target="_blank" rel="noreferrer">
                    [ DexScreener ]
                </a>
                <a href="https://x.com/rufusoneth" className="text-blue-900 hover:underline" target="_blank" rel="noreferrer">
                    [ Twitter ]
                </a>
                <a href="https://taxfarm.ing/" className="text-blue-900 hover:underline" target="_blank" rel="noreferrer">
                    [ TaxFarm ]
                </a>
            </div>
            <main className="fourchan-main mt-10">
                <div className="bg-white p-5 mb-5">
                    <div className="justify-between items-center border-y py-2 mb-2">
                        <span className="text-green-600 font-bold">Anonymous </span>
                        <span className="text-red-700 text-sm">10/10/2024 (Tue) 12:34:56</span>
                        <h5 className="text-red-700 text-sm">File: <a href='#' className='text-blue-700 underline'>rufus.jpg</a> (87kb, 300x230)</h5>
                    </div>
                    <div className="post-content flex">
                        <img src={Logo} alt="Sample" className="mr-5 w-1/5" />
                        <div className='p-5'>
                            <p className='text-green-600 font-bold'>welcome this is the $rufus coin, the next big thing. Buy now or regret later.</p>
                            <p className='mt-4'>
                                invest in <span className='bg-yellow-300'>$rufus</span> and secure your financial future today!
                                <br />
                                <span className='text-red-700 font-bold'>$rufusnoMIcs</span>
                                <br />
                                fair launch
                                <br />
                                lp burnt
                                <br />
                                0% tax
                            </p>
                            <p className='text-red-700 mt-4'>don't miss out, buy now or regret later, gonna be <span className='bg-yellow-300'>huge</span>.</p>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-5 mb-5">
                    <div className="justify-between items-center border-y py-2 mb-2">
                        <span className="text-green-600 font-bold">Anonymous </span>
                        <span className="text-red-700 text-sm">12/11/2024 (Tue) 11:24:56</span>
                        <h5 className="text-red-700 text-sm">File: <a href='#' className='text-blue-700 underline'>parents.jpg</a> (97kb, 400x230)</h5>
                    </div>
                    <div className="post-content flex">
                        <img src={meme2} alt="Sample" className="mr-5 w-1/5" />
                        <div className='p-5'>
                            <p className='text-red-700 font-bold'>- tfw $rufus mooning harder than Doge</p>
                            <p className='mt-4'>
                                be me
                                <br />
                                buy $rufus coin because why not
                                <br />
                                wake up next morning
                                <br />
                                check portfolio
                                <br />
                                tfw $rufus mooning harder than Doge
                                mfw I’m now a millionaire
                                <br />
                                thanks based $rufus
                                <br />
                                still can't tell my mom what $rufus is without her thinking I’m joking
                                <br />
                                profit
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-5 mb-5">
                    <div className="justify-between items-center border-y py-2 mb-2">
                        <span className="text-green-600 font-bold">Anonymous </span>
                        <span className="text-red-700 text-sm">12/11/2024 (Tue) 11:24:56</span>
                        <h5 className="text-red-700 text-sm">File: <a href='#' className='text-blue-700 underline'>world.jpg</a> (172kb, 320x450)</h5>
                    </div>
                    <div className="post-content flex">
                        <img src={meme3} alt="Sample" className="mr-5 w-1/5" />
                        <div className='p-5'>
                            <p className='text-blue-700 font-bold'>- buy $rufus coin because why not</p>
                            <p className='mt-4'>
                                see everyone talking about $rufus coin
                                <br />
                                decide to buy a bag because FOMO
                                <br />
                                tfw $rufus skyrockets overnight
                                <br />
                                mfw finally can afford to move out of parents' basement
                                <br />
                                tell friends about $rufus
                                <br />
                                they think it's a joke
                                <br />
                                watch them regret not buying in a few weeks later
                                <br />
                                thanks $rufus, very cool
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-5 mb-5">
                    <div className="justify-between items-center border-y py-2 mb-2">
                        <span className="text-green-600 font-bold">Anonymous </span>
                        <span className="text-red-700 text-sm">12/11/2024 (Tue) 11:24:56</span>
                        <h5 className="text-red-700 text-sm">File: <a href='#' className='text-blue-700 underline'>profit.jpg</a> (57kb, 500x430)</h5>
                    </div>
                    <div className="post-content flex">
                        <img src={meme4} alt="Sample" className="mr-5 w-1/5" />
                        <div className='p-5'>
                            <p className='text-purple-700 font-bold'>- wake up, check $rufus price</p>
                            <p className='mt-4'>
                                wake up, check $rufus price
                                <br />
                                it's still mooning
                                <br />
                                call boss to quit job
                                <br />
                                he laughs and thinks I'm joking
                                <br />
                                send him screenshot of my portfolio
                                <br />
                                he calls back and asks how to buy $rufus
                                <br />
                                laugh and hang up
                                <br />
                                profit
                            </p>
                        </div>
                    </div>
                </div>
                
            </main>
        </div>
    );
};

export default FourChan;
